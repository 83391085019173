
  import {  Vue, Component,Prop } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../config";
    // import { authHeader } from "../../services/auth";
    @Component  
    export default class LoanCard extends Vue {
      @Prop()
      public loanId
      @Prop()
      public loan

      public titleInfo: any = null;
      public hasRouteId: any = false
      public sessionObject: any = {}

      /* eslint-disable */
      public async getLoanDetails() {
        try {
         this.callLoader(true,null)
         let response
            //  var response = null
            if(this.loanId){
             response = await Axios.get( BASE_API_URL +
              `authentication/loanDetaillsforLoanCard/${this.loanId}`, );        
           }else{
             response = await Axios.get( BASE_API_URL +
              `authentication/loanDetaillsforLoanCard/${this.loan.loanId}`, );                 
           }

           if (response && response.status === 201) {
            this.titleInfo = response?.data?.obj || null;
           
              } 
            } catch (error) {
              console.log(error);
            }
            this.callLoader(false,null)
          }



      public clickOrder(id){
            if (this.$route.query.id) return
              this.$router.push({
                path: `/title-order?id=${id.loanId}`,
                query: { id: id.loanId }
                });
          }
      
      public clickLow(id){
            if (this.$route.query.id) return
              this.$router.push({
                path:`/title-low`,
                query: { id: id.loanId }
                });
          }

          public callLoader(loader, loaderTitleId) {
            this.$store.state.tmpcoLoader = loader;
            this.$store.state.loaderTitleId = loaderTitleId;
            this.sessionObject = this.$store.state.sessionObj;
          }

          public async mounted() {
           if (this.$route.query.loanId) this.hasRouteId = true
          await this.getLoanDetails()
         }
         /* eslint-disable */
       }
     