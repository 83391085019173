
import Axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import LoanCard from "../LoanCard.vue";

@Component({
  components: {
    LoanCard,
  },
})
export default class DashBoard extends Vue {
  public loansAssignedList: any = null;
  public selectedLoan = null;
  public loanId = null;
  public userInfo: any = null;
  public totalLoans: any = null;

  /* eslint-disable */
  public async getLoansAssignedToAgent() {
    let response = await Axios.get(
      BASE_API_URL + `agent/getLoansAssignedToAgent`,
      { headers: authHeader() }
    );
    this.loansAssignedList = response.data.data;
    console.log("--->", this.loansAssignedList);
    this.totalLoans = this.loansAssignedList.length;
    this.userInfo = response.data.agentInfo;
  }

  public async mounted() {
    await this.getLoansAssignedToAgent();
  }
  /* eslint-disable */
}
